import React from 'react';
import styled from '@emotion/styled';

import PageLayout from '../components/PageLayout';
import Sections from '../components/Sections';
import { ContentMain } from '../components/commonStyled';
import { AvailableSections } from '../models';
import icon from '../images/page-not-found.svg';
import { DEFAULT_ALT } from '../constants';

const Icon = styled.img`
    max-width: 400px;
    width: 100%;
    height: auto;
`;

export default () => {
    return (
        <PageLayout title="Страница не найдена">
            <ContentMain>
                <Icon src={icon} alt={DEFAULT_ALT} />
            </ContentMain>
            <Sections enabledSections={[AvailableSections.actionBlockMiddle]} />
        </PageLayout>
    );
};
